import Crypto from "crypto-js";

// 密钥
var publicKey = "7c4603c8a9503727f3b63e2f14db2adf";

// 加密方法
export function encryptFun(data) {
  let key = Crypto.enc.Base64.parse(publicKey);
  // let encrypt = Crypto.enc.Utf8.parse(data);
  let encrypted = Crypto.AES.encrypt(data, key, {
    mode: Crypto.mode.ECB,
    padding: Crypto.pad.Pkcs7,
  });

  return encrypted.toString();
}

// 解密方法
export function decrypt(data) {
  let key = Crypto.enc.Base64.parse(publicKey);
  let decrypted = Crypto.AES.decrypt(data, key, {
    mode: Crypto.mode.ECB,
    padding: Crypto.pad.Pkcs7,
  });

  return decrypted.toString(Crypto.enc.Utf8);
}
