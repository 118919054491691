import Vue from "vue";
import App from "./App.vue";
import Vant from "vant";
import "vant/lib/index.css";
import router from "./router";
import api from "./api/index";
import quertstring from "querystring";
// import "amfe-flexible";

Vue.use(Vant);
Vue.config.productionTip = false;
Vue.prototype.$api = api;
// 获取package.json中version，检测到版本变动，清除浏览器缓存
const VUE_APP_VERSION = require("../package.json").version;
const vers = window.localStorage.getItem("appVersion") || "";
if (VUE_APP_VERSION != vers) {
  localStorage.clear();
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload();
}
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
