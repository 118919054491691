import axios from "axios";
// import { Message, MessageBox } from "element-ui";
import router from "../router";
import { getBaseUrl } from "@/utils/tools";
import { encryptFun, decrypt } from "@/utils/encrypt";
import { Toast } from "vant";

// export const baseUrl = getBaseUrl(); // 自动获取地址
// export const baseUrl = "http://admin.ikukan.com/"; // 正式环境
// export const baseUrl = "http://desk-platform.inside.misetv.com/"; // 测试环境
export const baseUrl = "https://alipayh5.ikukan.com/"; // 开发环境
// export const baseUrl = "http://192.168.3.41:80/" // 本地环境
var service = axios.create({
  baseURL: baseUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
});

const downloadUrl = [
  "/api/desk/platform/internal/day/download",
  "/api/desk/platform/internal/app/location/download",
  "/api/desk/platform/internal/app/config/download",
  "/api/desk/platform/internal/app/download",
];

//请求拦截
service.interceptors.request.use(
  (config) => {
    //在请求前做点什么
    // config.headers.muksha = true;
    let token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    // 如果是get请求，url中添加时间戳清除缓存
    if (config.method == "get") {
      if (config.url.indexOf("?") > -1) {
        config.url = config.url + "&randomTime=" + Date.parse(new Date());
      } else {
        config.url = config.url + "?randomTime=" + Date.parse(new Date());
      }
    }
    // 如果是广告扫码认证，在每个接口前面加个/advert
    let advert = localStorage.getItem("advert");
    console.log(advert, advert == "true");
    if (advert == "true") {
      console.log(333);
      config.url = "/advert" + config.url;
    }
    // 如果是下载文档流相关接口，加一个专属请求头用来标记
    // 返回过来的文档流也是不加密的
    // 如果是get请求，url中添加时间戳清除缓存
    // 在参数传递至后端前，将参数进行AES加密
    if (config.data) {
      if (config.url === "/app/upload/image") {
        config.headers.muksha = true;
      } else {
        // 加密之前先转换为JSON字符串
        const data = JSON.stringify(config.data);
        config.data = {
          data: encryptFun(data),
        };
      }
    }
    return config;
  },

  (error) => {
    console.log("请求拦截=>", error);
    return error;
  }
);
//响应拦截
service.interceptors.response.use(
  (response) => {
    const responseData = response.data || {};
    // // 根据返回结果分为两种处理方式： 文件流 / 其它正常数据
    // // 文件流返回不进行加密处理，所以这里不需要进行解密，正常解析文件流即可
    if (typeof responseData === "object") {
      if (
        response.headers["content-type"] ===
        "application/force-download;charset=utf-8"
      ) {
        const fileName = decodeURI(
          response.headers["content-disposition"].split("filename=")[1]
        );
        return Promise.resolve({
          data: responseData,
          fileName: fileName,
          type: response.headers["content-type"],
        });
      } else {
        return responseData;
      }
    } else {
      // 除文件流之外，其它数据在后端统一进行了加密，这里需要对返回数据进行解密
      let resData = responseData?.length
        ? JSON.parse(decrypt(responseData))
        : responseData;
      // let resData = response.data;
      const { code, msg } = resData;
      const msgList = ["账号未登录!", "未获取到token内信息"];
      if (code && code !== 200) {
        if (msgList.includes(msg) || code == 160504 || code == 160649) {
          onError("账号已退出，请重新登录并扫描新的二维码");
          return Promise.reject();
        } else if (code == 160503) {
          onError("无权限");
          return resData || {};
        } else if (
          code == 161804 ||
          code == 161305 ||
          code == 161801 ||
          code == 161807
        ) {
          return resData || {};
        } else {
          onError(msg);
          return false;
        }
      } else {
        return resData || {};
      }
    }
  },
  (error) => {
    onError(error);
    if (
      typeof error.response === "object" &&
      typeof error.response?.data === "object"
    ) {
      if (
        error.response.data?.code === 401 ||
        error.response.data?.code === 403
      ) {
        let value =
          error.response?.data?.code == 401
            ? "账号未登录"
            : "账号不存在或被禁用";
        onError("账号已退出，请重新登录并扫描新的二维码");
        return Promise.reject(error);
      } else if (error.response?.data?.code === 429) {
        onError("无权限");
        return Promise.reject(error);
      } else {
        const message =
          (error?.response && error?.response?.data?.msg) || error?.message;
        onError(message);
        return Promise.reject(error);
      }
    }
  }
);

function onError(message) {
  Toast({
    message: `${message}`,
    icon: "cross",
  });
}

export default service;
