import request from "./request";
export default {
  // 是否已完成认证
  getCertification: (params) =>
    request.get(
      `/user/alipay/auth/check?uuid=${params.uuid}&timestamp=${params.timestamp}`
    ),
  // 查询人脸结果认证
  getFaceResult: (params) =>
    request.get(
      `/user/alipay/auth/result?uuid=${params.uuid}&timestamp=${params.timestamp}`
    ),
  // 认证状态
  getResultStatus: (params) =>
    request.get(
      `/user/alipay/auth/status?uuid=${params.uuid}&timestamp=${params.timestamp}`
    ),
  // 人脸识别url
  getUrlFace: (params) => request.post(`/user/alipay/auth/url`, params),
};
