import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// 修改原型对象中的push函数
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
// 修改原型对象中的replace函数
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

// 各模块路由引入

const routes = [
  {
    path: "/",
    name: "index",
    meta: {
      title: "实名认证",
      keepAlive: false,
    },
    component: () => import("@/views/index.vue"),
    children: [],
  },
  {
    path: "/agreement",
    name: "agreement",
    meta: {
      title: "协议",
      keepAlive: false,
      closable: false,
    },
    component: () => import("@/views/agreement.vue"),
  },
];

const router = new VueRouter({
  routes,
  mode: "hash",
});

export default router;
