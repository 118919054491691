<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="less">
* {
  padding: 0px;
  margin: 0px;
}

#app {

  background: #FBFBFB;
  height: 100vh;
  width: 100vw;
}
</style>
